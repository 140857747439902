import React, {Fragment, useEffect} from 'react';
import styled from "styled-components";
import Header from "./Header";
import {BaseButton, BaseContainer, BaseTitle} from "./Login";
import {FontAwesomeIconButton, LinkWithoutStyle, MainDiv} from "./Home";
import {fetchList} from "./ListSlice";
import {connect} from 'react-redux';
import {dateToFrenchFormat, getDayOfWeek, weekAfter, weekBefore} from "./Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {logout} from "./LoginSlice";

export const List = ({
    dispatch,
    match: {params: {year = null, week = null}},
    loading = true,
    captures = [],
    valid = false
}) => {
    useEffect(() => dispatch(fetchList(year, week)), [true]);
    let [yearBfr, weekBfr] = weekBefore(year, week);
    let [yearAft, weekAft] = weekAfter(year, week);
    return (
        <Fragment>
            <Header isAuth={true} logout={dispatch(logout)}/>
            <BaseContainer>
                <ListDiv>
                    <RowHeader>
                        <ElementHeader>
                            <LinkWithoutStyle to={'/list/' + yearBfr + '/' + weekBfr}>
                                <FontAwesomeIconButton
                                    icon={'arrow-circle-left'}
                                    size={'2x'}
                                    onClick={() => dispatch(fetchList(yearBfr, weekBfr))}
                                />
                            </LinkWithoutStyle>
                        </ElementHeader>
                        <TitleHeader>
                            <BaseTitle>Semaine {week} ({year})</BaseTitle>
                        </TitleHeader>
                        <ElementHeader>
                            <LinkWithoutStyle to={'/list/' + yearAft + '/' + weekAft}>
                                <FontAwesomeIconButton
                                    icon={'arrow-circle-right'}
                                    size={'2x'}
                                    onClick={() => dispatch(fetchList(yearAft, weekAft))}
                                />
                            </LinkWithoutStyle>
                        </ElementHeader>
                    </RowHeader>
                    { loading ?
                        <LoadingContainer>
                            <FontAwesomeIcon icon={'spinner'} spin={true} size={'3x'}/>
                        </LoadingContainer>
                        :
                        <Fragment>
                            { valid ?
                                <TitleValid>(Validée)</TitleValid>
                                :
                                <TitleInvalid>(Non validée)</TitleInvalid>
                            }
                            <TableContainer>
                                <Table>
                                    <tbody>
                                        <TableHeader>
                                            <th scope={'col'}>Date</th>
                                            <th scope={'col'}>Chantier</th>
                                            <th scope={'col'}>Heures</th>
                                        </TableHeader>
                                        {
                                            captures.map((capture, index) =>
                                                <TableRow valid={valid} key={index} onClick={() => {
                                                    if (!valid) {
                                                        window.location = '/edit/' + capture.date + '/' + capture.order
                                                    }
                                                }}>
                                                    <th scope={'row'}>{getDayOfWeek(capture.date).substr(0, 3) + '. ' + dateToFrenchFormat(capture.date)}</th>
                                                    <Td>{capture.customer}</Td>
                                                    <Td>{capture.hours}</Td>
                                                </TableRow>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </TableContainer>
                        </Fragment>
                    }
                    <ButtonDiv>
                        <Link to={'/home'}><BaseButton>Nouvelle saisie</BaseButton></Link>
                    </ButtonDiv>
                </ListDiv>
            </BaseContainer>
        </Fragment>
    )
};

export default connect(
    (state) => ({
        loading: state.listReducer.loading,
        captures: state.listReducer.captures,
        valid: state.listReducer.valid
    })
)(List);

export const LoadingContainer = styled.div`
    text-align: center;
    margin: 20px 0;
`;

export const ElementHeader = styled.div`
    margin-top: 20px;
    width: 100%;
`;

export const TitleHeader = styled.div`
    // margin-top: 15px;
    width: 100%;
`;

export const RowHeader = styled.div`
    display: flex;
    text-align: center;
`;

export const ButtonDiv = styled.div`
    padding: 15px;
    padding-bottom: 0;
`;

export const ListDiv = styled(MainDiv)`
    padding-right: 0;
    padding-left: 0;
`;

export const TableContainer = styled.div`
    text-align: center;
    margin-top: 30px;
`;

export const Table = styled.table`
    text-align: center;
    margin-left:auto; 
    margin-right:auto;
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.tr`
    border-bottom: 1px solid #494a4c;
`;

export const TableRow = styled.tr`
    margin-bottom: 1px;
    transition: all .1s ease-in-out;
    ${props => !props.valid ? `
        box-shadow: 0px 1px 2px #98999B;
        &:hover {
            cursor: pointer;
            opacity: .5;
            transform: scale(1.01);
        }
        text-decoration: underline;`
    :
        'border-bottom: 1px solid #C8C9CB;'
    }
`;

export const Td = styled.td`
    height: 50px;
    vertical-align: middle;
`;

export const TitleValid = styled(BaseTitle)`
    color: green;
    margin-top: -15px;
`;

export const TitleInvalid = styled(BaseTitle)`
    color: orange;
    margin-top: -15px;
`;