import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {reducer as homeReducer} from "./HomeSlice";
import {reducer as oAuthReducer} from "./OAuthTokenReader";
import {reducer as loginReducer} from "./LoginSlice";
import {reducer as listReducer} from "./ListSlice";

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export const store = configureStore({
    reducer: {
        homeReducer,
        loginReducer,
        listReducer,
        oAuthReducer
    }
});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
