export const todayHTMLFormat = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return [year, month, day].join('-');
};

export const weekday = [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi'
];

export const getDayOfWeek = (date) => {
    let d = new Date(date);

    return weekday[d.getDay()];
};

export const getWeekFromDate = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
};

export const formErrors = ({
    site,
    date,
    mode,
    zone,
    fwdTrip,
    rtnTrip,
    hours,
    companions
}) => {
    let errors = [];
    if (site === null) {
        errors.push('site');
    }
    if (date === null || date === "") {
        errors.push('date');
    }
    if (mode !== 1 && mode !== 2 && mode !== 3) {
        errors.push('mode');
    }
    if (mode === 1 && (zone > 5 || zone < 1)) {
        errors.push('zone');
    }
    if ((mode === 2 || mode === 3) && (fwdTrip > 12 || fwdTrip < 0)) {
        errors.push('fwdTrip');
    }
    if ((mode === 2 || mode === 3) && (rtnTrip > 12 || rtnTrip < 0)) {
        errors.push('rtnTrip');
    }
    if (hours > 12 || hours < 0) {
        errors.push('hours');
    }
    companions.forEach((companion, index) => {
        if (companion.id === null || companion.id === 0 || companion.id === "") {
            errors.push('companion|' + index.toString() + '|id');
        }
        if (companion.commentChoice === "oth" && (companion.comment === null || companion.comment === "")) {
            errors.push('companion|' + index.toString() + '|comment');
        }
    });

    return errors;
};

export const dateToFrenchFormat = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return [day, month].join('/');
};

export const weekBefore = (year, week) => {
    if (week > 1) {
        return [year, (parseInt(week) - 1)];
    }

    let d = new Date(year + '-01-01');
    let weekNo = 1;
    for (let i = 0; i < 7; i++) {
        d.setDate(d.getDate() - 1);
        weekNo = getWeekFromDate(d);
        if (weekNo !== 1) {
            return [(parseInt(year) - 1), weekNo];
        }
    }
};

export const weekAfter = (year, week) => {
    if (week < 52) {
        return [year, (parseInt(week) + 1)];
    }

    if (parseInt(week) === 52) {
        let d = new Date(year + '-12-24');
        let weekNo = 52;
        for (let i = 0; i < 7; i++) {
            d.setDate(d.getDate() + 1);
            weekNo = getWeekFromDate(d);
            if (weekNo === 1) {
                return [(parseInt(year) + 1), weekNo];
            } else if (weekNo === 53) {
                return [year, weekNo];
            }
        }
    }
    return [(parseInt(year) + 1), 1];
};