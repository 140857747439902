export const authenticate = ({name, password}) => {
    let data = new FormData();
    data.append('grant_type','password');
    //data.append('client_id','1_17u08xewiuw0k4g8ww4000ok8gg40csgc0gko8o80ggc0wskg0');
    //data.append('client_secret','4j0deayjnw8w4ogo4wgokcg08kkc0soss4skko8cswsswo0cwk');
    data.append('client_id',process.env.REACT_APP_CLIENT_ID)
    data.append('client_secret',process.env.REACT_APP_CLIENT_SECRET);
    data.append('username', name);
    data.append('password', password);
    return fetch(process.env.REACT_APP_API_URL + '/oauth/v2/token', {
        method: 'post',
        body: data
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            return data
        })
};
export const refreshAccessToken = () => {
    let data = new FormData();
    data.append('grant_type','refresh_token');
    //data.append('client_id','1_17u08xewiuw0k4g8ww4000ok8gg40csgc0gko8o80ggc0wskg0');
    //data.append('client_secret','4j0deayjnw8w4ogo4wgokcg08kkc0soss4skko8cswsswo0cwk');
    data.append('client_id',process.env.REACT_APP_CLIENT_ID)
    data.append('client_secret',process.env.REACT_APP_CLIENT_SECRET);
    data.append('refresh_token', localStorage.getItem('refresh_token'));
    return fetch(process.env.REACT_APP_API_URL + '/oauth/v2/token', {
        method: 'post',
        body: data
    }).then(response => {
        return response.json()
    }).then(data => {
        return data
    })
};

export const checkToken = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/check_token',{
        method: 'get',
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            return data
        })
};
