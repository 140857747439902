import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Header = ({
    isAuth = false,
    logout
}) =>
    <HeaderDiv>
        <LogoImg src={'/air8_logo.jpg'}/>
        { isAuth &&
            <LogoutContainer>
                <LogoutIcon icon={'sign-out-alt'} onClick={logout}/>
            </LogoutContainer>
        }
    </HeaderDiv>
;

export default Header;

export const LogoImg = styled.img`
    height: 100px;
`;

export const HeaderDiv = styled.div`
    padding: 10px 0;
    box-shadow: 0px 1px 8px black;
    text-align: center;
    background-color: white;
`;

export const LogoutContainer = styled.div`
    position: relative;
    text-align: center;
`;

export const LogoutIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 10px;
    margin-bottom: 32px;
    @media (min-width: 768px) {
        margin-right: 5%;
    }
    @media (min-width: 992px) {
        margin-right: 15%;
    }
    @media (min-width: 1200px) {
        margin-right: 25%;
    }
    &:hover {
        transition: all .1s ease-in-out;
        opacity: .5;
        transform: scale(1.1);
        cursor: pointer;
    }
    box-shadow: 0px 1px 2px black;
    font-size: 20px;
    padding: 8px;
    border-radius: 20px;
    color: white;
    background: linear-gradient(to left, #004273, #019ee3);
`;