import {createSlice} from "@reduxjs/toolkit";
import {authenticate} from "./OAuth2Utils";
import {fetchToken} from "./OAuthTokenReader";

export const initialState = {
    name: '',
    password: '',
    loading: false
};

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        typeName: (state, action) => ({
            ...state,
            name: action.payload
        }),
        typePassword: (state, action) => ({
            ...state,
            password: action.payload
        }),
        setToken: (state, action) => ({
            ...state,
            token: action.payload,
            authenticated: true
        }),
        setError: (state, action) => ({
            ...state,
            error: action.payload
        }),
        setAdmin: (state, action) => ({
            ...state,
            admin: action.payload
        }),
        setAuthenticated: (state, action) => ({
            ...state,
            authenticated: action.payload.value,
            name: action.payload.value ? state.name : '',
            token: action.payload.value ? state.token : '',
            admin: action.payload.value ? state.admin : false
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload
        })
    }
});

export const {actions, reducer} = loginSlice;

export const {
    typeName,
    typePassword,
    setError,
    setLoading
} = actions;

export const sendLogin = ({name, password}) => dispatch => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    authenticate({name, password})
        .then(data => {
            if (data.hasOwnProperty('access_token') && data.access_token !== '') {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                dispatch(fetchToken());
            }
            if (data.hasOwnProperty('error') && data.error !== '') {
                dispatch(setError(true));
                dispatch(setLoading(false));
            }
        });
};

export const logout = () => dispatch => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.pathname = '/login';
};