import {todayHTMLFormat} from "./Utils";
import {isEmpty, remove, update} from 'ramda';
import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    form: {
        site: null,
        date: todayHTMLFormat(),
        mode: 1,
        zone: 1,
        fwdTrip: 0,
        rtnTrip: 0,
        hours: 8,
        companions: []
    },
    companions: [],
    sites: [],
    user: {},
    loading: false,
    errors: [],
    success: false,
    loadingInfo: false
};

export const homeSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setUser: (state, action) => ({
            ...state,
            user: action.payload
        }),
        setOrders: (state, action) => ({
            ...state,
            sites: action.payload
        }),
        setCompanions: (state, action) => ({
            ...state,
            companions: action.payload
        }),
        selectSite: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                site: action.payload,
                companions: state.form.companions.map(companion => ({
                    ...companion,
                    site: action.payload
                }))
            }
        }),
        selectDate: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                date: action.payload,
                companions: state.form.companions.map(companion => ({
                    ...companion,
                    date: action.payload
                }))
            }
        }),
        selectMode: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                mode: parseInt(action.payload)
            }
        }),
        selectHours: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                hours: parseFloat(action.payload)
            }
        }),
        selectZone: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                zone: parseInt(action.payload)
            }
        }),
        selectFwdTrip: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                fwdTrip: parseFloat(action.payload)
            }
        }),
        selectRtnTrip: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                rtnTrip: parseFloat(action.payload)
            }
        }),
        addCompanion: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                companions: state.companions.length !== state.form.companions.length ?
                    [...state.form.companions, action.payload]
                    : state.form.companions
            }
        }),
        removeCompanion: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                companions: remove(action.payload, 1, state.form.companions)
            }
        }),
        selectCompanionName: (state, {payload: {index, id}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    id
                }, state.form.companions)
            }
        }),
        selectCompanionHours: (state, {payload: {index, hours}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    hours: (hours > 12 || hours < 1) ?
                        state.form.companions[index].hours
                        : hours
                }, state.form.companions)
            }
        }),
        selectCompanionMode: (state, {payload: {index, mode}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    mode: parseInt(mode)
                }, state.form.companions)
            }
        }),
        selectCompanionZone: (state, {payload: {index, zone}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    zone: parseInt(zone)
                }, state.form.companions)
            }
        }),
        selectCompanionFwdTrip: (state, {payload: {index, fwdTrip}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    fwdTrip: parseFloat(fwdTrip)
                }, state.form.companions)
            }
        }),
        selectCompanionRtnTrip: (state, {payload: {index, rtnTrip}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    rtnTrip: parseFloat(rtnTrip)
                }, state.form.companions)
            }
        }),
        chooseCompanionComment: (state, {payload: {index, commentChoice}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    commentChoice
                }, state.form.companions)
            }
        }),
        typeCompanionComment: (state, {payload: {index, comment}}) => ({
            ...state,
            form: {
                ...state.form,
                companions: update(index, {
                    ...state.form.companions[index],
                    comment
                }, state.form.companions)
            }
        }),
        repercussionHours: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                companions: state.form.companions.map(companion => ({
                    ...companion,
                    hours: action.payload
                }))
            }
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload
        }),
        setErrors: (state, action) => ({
            ...state,
            errors: action.payload
        }),
        reinitForm: (state, action) => ({
            ...state,
            form: {
                ...state.form,
                mode: 1,
                zone: 1,
                fwdTrip: 0,
                rtnTrip: 0,
                hours: 8,
                companions: []
            }
        }),
        setSuccess: (state, action) => ({
            ...state,
            success: action.payload
        }),
        setForm: (state, action) => ({
            ...state,
            form: action.payload
        }),
        setLoadingInfo: (state, action) => ({
            ...state,
            loadingInfo: action.payload
        }),
    }
});

export const fetchInfos = (date, order) => dispatch => {
    dispatch(reinitForm());
    dispatch(setLoadingInfo(true));

    let uri = '/api/capture/info';

    if (date && order) {
        uri += '/' + date + '/' + order;
    }

    fetch(process.env.REACT_APP_API_URL + uri, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            dispatch(setLoadingInfo(false));
            if (data.status === "success") {
                dispatch(setOrders(data.response.orders));
                dispatch(setCompanions(data.response.companions));
                if (data.response.form) {
                    dispatch(setForm(data.response.form));
                }
                if (data.response.lastCapture && !isEmpty(data.response.lastCapture)) {
                    let lastCapture = data.response.lastCapture;
                    dispatch(selectSite(lastCapture.order.toString()));
                    lastCapture.companions.forEach((companion) => {
                        dispatch(addCompanion(companion));
                    });
                }
            } else {
                // TODO : error message
            }
        });
};

export const saveCapture = (form) => dispatch => {
    fetch(process.env.REACT_APP_API_URL + '/api/capture/add', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        },
        body: form
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            dispatch(setLoading(false));
            if (data.status === 'success') {
                dispatch(setSuccess(true));
                dispatch(reinitForm());
            }
        });
};

export const editCapture = (form) => dispatch => {
    fetch(process.env.REACT_APP_API_URL + '/api/capture/update', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        },
        body: form
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            window.scrollTo(0, 0);
            dispatch(setLoading(false));
            if (data.status === 'success') {
                dispatch(setSuccess(true));
            }
        });
};

export const {actions, reducer} = homeSlice;

export const {
    setUser,
    setOrders,
    setCompanions,
    selectSite,
    selectDate,
    selectMode,
    selectHours,
    selectZone,
    selectFwdTrip,
    selectRtnTrip,
    addCompanion,
    removeCompanion,
    selectCompanionName,
    selectCompanionHours,
    selectCompanionMode,
    selectCompanionZone,
    selectCompanionFwdTrip,
    selectCompanionRtnTrip,
    chooseCompanionComment,
    typeCompanionComment,
    repercussionHours,
    setLoading,
    setErrors,
    reinitForm,
    setSuccess,
    setForm,
    setLoadingInfo
} = actions;
