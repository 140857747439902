import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createSlice} from '@reduxjs/toolkit'
import {checkToken, refreshAccessToken} from "./OAuth2Utils";
import {setUser} from "./HomeSlice";
import {logout} from "./LoginSlice";

export const oAuthSlice = createSlice({
    name: 'oauth',
    initialState:  {
        loadingUser: true,
        authenticated: false,
        user: {}
    },
    reducers: {
        userLoaded: (state, action) => ({
            ...state,
            loadingUser: false,
            authenticated: true,
            user: action.payload
        })
    }
});

export const {actions, reducer} = oAuthSlice;
export const {userLoaded} = actions;

export const fetchToken = () => dispatch => {
    let accessToken = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');
    if (accessToken !== null || refreshToken !== null) {
        checkToken()
            .then(data => {
                if (data.valid === true) {
                    dispatch(setUser(data.user));
                    dispatch(userLoaded(data.user));
                    if (window.location.pathname === '/login' || window.location.pathname === '/') {
                        window.location.pathname = '/home';
                    }
                } else {
                    if (data.error === 'invalid_grant') {
                        console.log(401);
                        refreshAccessToken()
                            .then(data => {
                                if (data.hasOwnProperty('access_token') && data.access_token !== '') {
                                    localStorage.setItem('access_token', data.access_token);
                                    localStorage.setItem('refresh_token', data.refresh_token);
                                    checkToken()
                                        .then(data => {
                                            if (data.valid) {
                                                dispatch(setUser(data.user));
                                                dispatch(userLoaded(data.user));
                                                if (window.location.pathname === '/login' || window.location.pathname === '/') {
                                                    window.location.pathname = '/home';
                                                }
                                            }
                                        })
                                } else if (window.location.pathname !== '/login') {
                                    window.location.pathname = '/login';
                                }
                            })
                    }
                }
            }).catch(error => dispatch(logout()))
    } else if (window.location.pathname !== '/login'){
        window.location.pathname = '/login';
    }
};

export const OAuthTokenReader = ({
    dispatch
}) => {
    useEffect(() => dispatch(fetchToken()), [true]);
    return null;
};

export default connect(
    state => ({
        authenticated: state.oAuthReducer.authenticated,
        loadingUser: state.oAuthReducer.loadingUser,
        user: state.oAuthReducer.user,
    })
)(OAuthTokenReader)