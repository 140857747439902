import React, {Fragment} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    typeName,
    typePassword,
    sendLogin
} from './LoginSlice';
import {connect} from 'react-redux';
import Header from "./Header";

export const Login = ({
    name = '',
    password = '',
    error = false,
    loading = false,
    dispatch,
}) =>
    <Fragment>
        <Header isAuth={false}/>
        <BaseContainer>
            <BaseDiv>
                <BaseTitle>Connexion <FontAwesomeIcon icon={'key'} size={'xs'}/></BaseTitle>
                { error &&
                    <MessageContainer type={'error'}>
                        <Message>
                            Les identifiants sont incorrects
                            <IconError icon={'exclamation-triangle'}/>
                        </Message>
                    </MessageContainer>
                }
                <form action={'/home'}>
                    <BaseFormGroupDiv>
                        <label htmlFor="name">Identifiant</label>
                        <BaseInput
                            type="text"
                            id="username"
                            required
                            onChange={e => dispatch(typeName(e.currentTarget.value))}
                            defaultValue={name}
                        />
                    </BaseFormGroupDiv>
                    <BaseFormGroupDiv>
                        <label htmlFor="password">Mot de passe</label>
                        <BaseInput
                            type="password"
                            id="password"
                            required
                            onChange={e => dispatch(typePassword(e.currentTarget.value))}
                        />
                    </BaseFormGroupDiv>
                    <BaseFormGroupDiv className="row justify-content-center">
                        { loading ?
                            <BaseButton disabled>
                                <FontAwesomeIcon icon={'spinner'} spin/>
                            </BaseButton>
                            :
                            <BaseButton
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch(sendLogin({name, password}));
                                }}
                            >
                                Se connecter
                            </BaseButton>
                        }
                    </BaseFormGroupDiv>
                </form>
            </BaseDiv>
        </BaseContainer>
    </Fragment>;

export default connect(
    state => ({
        name: state.loginReducer.name,
        password: state.loginReducer.password,
        error: state.loginReducer.error,
        loading: state.loginReducer.loading
    }),
)(Login)

export const BaseDiv = styled.div`
    padding: 10px 15px 30px 15px;
    display: inline-block;
    width: 100%;
    margin: 15px 0px;
    @media (min-width: 768px) {
        width: 50%;
    }
    @media (min-width: 992px) {
        width: 40%;
    }
    @media (min-width: 1200px) {
        width: 30%;
    }
    box-shadow: 0px 1px 8px black;
    background-color: white;
    border-radius: 3px;
    text-align: left;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
`;

export const BaseContainer = styled.div`
    text-align: center;
`;

export const BaseTitle = styled.h2`
    text-align: center;
    color: #494a4c;
`;

export const BaseFormGroupDiv = styled.div`
    margin-top: 15px;
    width: 100%;
`;

export const BaseButton = styled.button`
    width: 100%;
    padding: 12px 0px;
    border-radius: 30px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(to left, #004273, #019ee3);
    cursor: pointer;
    transition-duration: 500ms;
    &:hover {
        opacity: 0.8;
        transform: scale(1.01);
        cursor: ${props => props.disabled ? 'wait' : 'pointer'};
    }
    box-shadow: 0px 1px 2px black;
`;

export const BaseInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    margin: 6px 0px;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    border: ${props => props.error ?
    "1px solid #D47576" : "1px solid #ccc"};
    box-shadow: ${props => props.error ?
    "inset 0 1px 5px #D47576" : "inset 0 1px 1px rgba(0,0,0,.075)"};
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
`;

export const MessageContainer = styled.div`
    border: ${props => props.type === 'success' ? '1px dashed #809776' : '1px dashed #D47576'};
    border-radius: 10px;
    padding: 8px;
    padding-left: 12px;
    background-color: ${props => props.type === 'success' ? '#DDFFCD' : '#FFC8C3'};
`;

export const Message = styled.div`
    display: table-cell;
    vertical-align: middle;
    color: #656668;
`;

export const IconError = styled(FontAwesomeIcon)`
    color: #D47576;
    margin-left: 8px;
`;