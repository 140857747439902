import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import List from "./List";
import OAuthTokenReader from "./OAuthTokenReader";

export const App = () =>
<Router>
    <OAuthTokenReader/>
    <Switch>
        <Route path="/login" component={Login} exact={true}/>
        <Route path="/home" component={Home}/>
        <Route path="/list/:year/:week" component={List}/>
        <Route path="/edit/:date/:order" component={Home}/>
    </Switch>
</Router>;

export default App;
