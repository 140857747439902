import React, {Fragment, useEffect} from 'react';
import {
    BaseButton,
    BaseDiv,
    BaseFormGroupDiv,
    BaseInput,
    BaseTitle,
    BaseContainer,
    MessageContainer,
    Message,
    IconError
} from "./Login";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {
    selectSite,
    selectDate,
    selectMode,
    selectHours,
    selectZone,
    selectFwdTrip,
    selectRtnTrip,
    addCompanion,
    removeCompanion,
    selectCompanionName,
    selectCompanionHours,
    selectCompanionMode,
    selectCompanionZone,
    selectCompanionFwdTrip,
    selectCompanionRtnTrip,
    chooseCompanionComment,
    typeCompanionComment,
    repercussionHours,
    setLoading,
    setErrors,
    fetchInfos,
    saveCapture,
    setSuccess,
    editCapture
} from './HomeSlice';
import {formErrors, getDayOfWeek, getWeekFromDate} from "./Utils";
import {propEq, filter, includes} from "ramda";
import Header from "./Header";
import {logout} from "./LoginSlice";
import {Link} from "react-router-dom";

export const zones = [1, 2, 3, 4, 5];
export const Home = ({
    dispatch,
    form = {},
    companions = [],
    sites = [],
    user = {},
    errors = [],
    loading = false,
    success = false,
    loadingInfo = false,
    match: {params: {date = null, order = null}}
 }) => {
    useEffect(() => dispatch(fetchInfos(date, order)), [true]);
    let d = date === null ? new Date(form.date) : new Date(date);
    // console.log
    console.log(sites)
    console.log(form.site)

    let activeSiteSearch = filter(propEq('id', form.site))(sites)
    let activeSite = activeSiteSearch.length > 0 ? activeSiteSearch[0] : null
console.log(activeSite)
    return (
        <Fragment>
            <Header isAuth={true} logout={dispatch(logout)}/>
            <BaseContainer>
                <MainDiv>
                    <UsernameContainer>
                        <UsernameBadge>Bonjour {user.fullname}</UsernameBadge>
                    </UsernameContainer>
                    <LinkWithoutStyle to={'/list/' + d.getFullYear() + '/' + getWeekFromDate(d)}>
                        <ArrowToList
                            icon={'arrow-left'}
                            size={'lg'}
                            // onClick={() => window.location = }
                        />
                    </LinkWithoutStyle>
                    <BaseTitle>{ date && order ? 'Modification' : 'Saisie'}</BaseTitle>
                    { loadingInfo ?
                        <LoadingContainer>
                            <FontAwesomeIcon icon={'spinner'} spin={true} size={'3x'}/>
                        </LoadingContainer>
                        :
                        <Fragment>
                            { errors.length !== 0 &&
                            <MessageContainer type={'error'}>
                                <Message>
                                    Veuillez corriger les erreurs de saisie
                                    <IconError icon={'exclamation-triangle'}/>
                                </Message>
                            </MessageContainer>
                            }
                            { success &&
                            <MessageContainer type={'success'}>
                                <Message>
                                    Votre saisie est bien enregistrée.
                                </Message>
                            </MessageContainer>
                            }
                            <form action={'/home'}>
                                <FormRow>
                                    <FormGroupLeft>
                                        <label htmlFor="chantier">Chantier</label>
                                        <BaseSelect
                                            onChange={e => dispatch(selectSite(e.currentTarget.value))}
                                            value={form.site}
                                            error={errors.includes('site')}
                                        >
                                            <option hidden/>
                                            {
                                                sites.map((site, index) =>
                                                    <option
                                                        key={index}
                                                        value={site.id}
                                                    >
                                                        {site.id + ' - ' + site.name}
                                                    </option>
                                                )
                                            }
                                        </BaseSelect>
                                    </FormGroupLeft>
                                    <FormGroupRight>
                                        <label htmlFor="date">Jour { form.date !== "" &&
                                        '(' + getDayOfWeek(form.date) + ')'
                                        }</label>
                                        <BaseInput
                                            type={"date"}
                                            value={form.date}
                                            onChange={e => dispatch(selectDate(e.currentTarget.value))}
                                            error={errors.includes('date')}
                                            required
                                        />
                                    </FormGroupRight>
                                </FormRow>
                                <FormRow>
                                    <FormGroupLeft onClick={() => dispatch(selectMode(1))}>
                                        <input
                                            type={'radio'}
                                            value={1}
                                            checked={form.mode === 1}
                                            onChange={() => {}}
                                        />
                                        <label>Local</label>
                                    </FormGroupLeft>
                                    <FormGroupRight onClick={() => dispatch(selectMode(3))}>
                                        <input
                                            type={'radio'}
                                            value={3}
                                            checked={form.mode === 3}
                                            onChange={() => {}}
                                        />
                                        <label>GDSN</label>
                                    </FormGroupRight>
                                    <FormGroupRight onClick={() => dispatch(selectMode(2))}>
                                        <input
                                            type={'radio'}
                                            value={2}
                                            checked={form.mode === 2}
                                            onChange={() => {}}
                                        />
                                        <label>Grand Déplacement</label>
                                    </FormGroupRight>
                                </FormRow>
                                { form.mode === 2 || form.mode === 3 ?
                                    <FormRow>
                                        <FormGroupLeft>
                                            <label htmlFor="trajetAller">Temps de trajet aller</label>
                                            <SelectHours
                                                onChange={e => dispatch(selectFwdTrip(e.currentTarget.value))}
                                                hourSelected={form.fwdTrip}
                                                zeroOption
                                            />
                                        </FormGroupLeft>
                                        <FormGroupRight>
                                            <label htmlFor="trajetRetour">Temps de trajet retour</label>
                                            <SelectHours
                                                onChange={e => dispatch(selectRtnTrip(e.currentTarget.value))}
                                                hourSelected={form.rtnTrip}
                                                zeroOption
                                            />
                                        </FormGroupRight>
                                    </FormRow>
                                    :
                                    <FormRow>
                                        <BaseFormGroupDiv>
                                            <label htmlFor="nbHour">Zone</label>
                                            <BaseSelect
                                                onChange={e => dispatch(selectZone(e.currentTarget.value))}
                                                value={form.zone}
                                            >
                                                {
                                                    zones.map((zone, index) =>
                                                        <option key={index} value={zone}>
                                                            Zone {zone}
                                                        </option>
                                                    )
                                                }
                                            </BaseSelect>
                                        </BaseFormGroupDiv>
                                    </FormRow>
                                }
                                { form.companions.length > 0 ?
                                    <FormRow>
                                        <FormGroupLeft>
                                            <label htmlFor="nbHour">Heures de travail</label>
                                            <SelectHours
                                                onChange={e => dispatch(selectHours(e.currentTarget.value))}
                                                hourSelected={form.hours}
                                            />
                                        </FormGroupLeft>
                                        <FormGroupRight>
                                            <SmallButton onClick={e => {
                                                e.preventDefault();
                                                dispatch(repercussionHours(form.hours));
                                            }}>
                                                <FontAwesomeIcon icon={'arrow-down'}/>
                                            </SmallButton>
                                        </FormGroupRight>
                                    </FormRow>
                                    :
                                    <FormRow>
                                        <BaseFormGroupDiv>
                                            <label htmlFor="nbHour">Heures de travail</label>
                                            <SelectHours
                                                onChange={e => dispatch(selectHours(e.currentTarget.value))}
                                                hourSelected={form.hours}
                                                zeroOption={true}
                                            />
                                        </BaseFormGroupDiv>
                                    </FormRow>
                                }
                                { form.companions.length > 0 &&
                                <BaseFormGroupDiv>
                                    <label>Accompagnants</label>
                                    {
                                        form.companions.map((companion, index) =>
                                            <CompanionLine
                                                companion={companion}
                                                key={index}
                                                dispatch={dispatch}
                                                index={index}
                                                companions={companions}
                                                errors={errors}
                                            />
                                        )
                                    }
                                </BaseFormGroupDiv>
                                }
                                <FormRow>
                                    <BaseFormGroupDiv style={{textAlign: 'right'}}>
                                        <MailTo subject={
                                            form.site ?
                                            'Chantier : ' + form.site + ' - ' + (activeSite !== null ? activeSite.name : ''):
                                            ''
                                        }/>
                                    </BaseFormGroupDiv>
                                </FormRow>
                                { form.companions.length !== companions.length &&
                                <BaseFormGroupDiv className="row justify-content-center">
                                    <AddButton onClick={e => {
                                        e.preventDefault();
                                        dispatch(addCompanion({
                                            ...form,
                                            id: 0,
                                            commentChoice: null,
                                            comment: null,
                                            companions: []
                                        }));
                                    }}>
                                        Ajouter accompagnant
                                    </AddButton>
                                </BaseFormGroupDiv>
                                }
                                <BaseFormGroupDiv className="row justify-content-center">
                                    { loading ?
                                        <BaseButton disabled>
                                            <FontAwesomeIcon icon={'spinner'} spin/>
                                        </BaseButton>
                                        :
                                        <Fragment>
                                            { date && order ?
                                                <BaseButton onClick={e => {
                                                    e.preventDefault();
                                                    dispatch(setLoading(true));
                                                    dispatch(setSuccess(false));
                                                    dispatch(setErrors([]));
                                                    if (formErrors(form).length === 0) {
                                                        dispatch(editCapture(JSON.stringify(form)));
                                                    } else {
                                                        window.scrollTo(0, 0);
                                                        dispatch(setErrors(formErrors(form)));
                                                        dispatch(setLoading(false));
                                                    }
                                                }}>
                                                    Modifier
                                                </BaseButton>
                                                :
                                                <BaseButton onClick={e => {
                                                    e.preventDefault();
                                                    dispatch(setLoading(true));
                                                    dispatch(setSuccess(false));
                                                    dispatch(setErrors([]));
                                                    if (formErrors(form).length === 0) {
                                                        dispatch(saveCapture(JSON.stringify(form)));
                                                    } else {
                                                        window.scrollTo(0, 0);
                                                        dispatch(setErrors(formErrors(form)));
                                                        dispatch(setLoading(false));
                                                    }
                                                }}>
                                                    Valider
                                                </BaseButton>
                                            }
                                        </Fragment>
                                    }
                                </BaseFormGroupDiv>
                            </form>
                        </Fragment>
                    }
                </MainDiv>
            </BaseContainer>
        </Fragment>
    )
;
};

export default connect(
    (state) => ({
        form: state.homeReducer.form,
        companions: state.homeReducer.companions,
        sites: state.homeReducer.sites,
        user: state.homeReducer.user,
        loading: state.homeReducer.loading,
        loadingInfo: state.homeReducer.loadingInfo,
        errors: state.homeReducer.errors,
        success: state.homeReducer.success
    })
)(Home);

export const commentChoices = [
    {
        value: null,
        text: ''
    },
    {
        value: 'rtd',
        text: 'Retard'
    },
    {
        value: 'abs',
        text: 'Absent'
    },
    {
        value: 'oth',
        text: 'Autre'
    }
];
export const CompanionLine = ({
    companions,
    companion,
    index,
    errors = [],
    dispatch
}) =>
    <CompanionDiv>
        <DeleteButtonContainer>
            <DeleteButton icon={'times'} onClick={() => dispatch(removeCompanion(index))}/>
        </DeleteButtonContainer>
        <FormRow>
            <FormGroupLeft>
                <label htmlFor="name">Nom</label>
                <BaseSelect
                    onChange={e => dispatch(selectCompanionName({
                        id: parseInt(e.currentTarget.value),
                        index
                    }))}
                    value={companion.id}
                    error={errors.includes('companion|' + index.toString() + '|id')}
                >
                    <option hidden/>
                        {
                            companions.map((companion, index) =>
                                <option
                                    key={index}
                                    value={companion.id}
                                >
                                    {companion.name}
                                </option>
                            )
                        }
                </BaseSelect>
            </FormGroupLeft>
            <FormGroupRight>
                <label htmlFor="nbHour">Heures de travail</label>
                <SelectHoursArrow
                    hours={companion.hours}
                    onClickLeft={() => dispatch(selectCompanionHours({
                        hours: (parseFloat(companion.hours)-0.5),
                        index
                    }))}
                    onClickRight={() => dispatch(selectCompanionHours({
                        hours: (parseFloat(companion.hours)+0.5),
                        index
                    }))}
                    index={index}
                />
            </FormGroupRight>
        </FormRow>
        <FormRow>
            <FormGroupLeft onClick={() => dispatch(selectCompanionMode({
                mode: 1,
                index
            }))}>
                <input
                    type={'radio'}
                    value={1}
                    checked={companion.mode === 1}
                    onChange={() => {}}
                />
                <label>Local</label>
            </FormGroupLeft>
            <FormGroupRight onClick={() => dispatch(selectCompanionMode({
                mode: 3,
                index
            }))}>
                <input
                    type={'radio'}
                    value={3}
                    checked={companion.mode === 3}
                    onChange={() => {}}
                />
                <label>GDSN</label>
            </FormGroupRight>
            <FormGroupRight onClick={() => dispatch(selectCompanionMode({
                mode: 2,
                index
            }))}>
                <input
                    type={'radio'}
                    value={2}
                    checked={companion.mode === 2}
                    onChange={() => {}}
                />
                <label>Grand Déplacement</label>
            </FormGroupRight>
        </FormRow>
        { companion.mode === 2 || companion.mode === 3 ?
            <FormRow>
                <FormGroupLeft>
                    <label htmlFor="trajetAller">Temps de trajet aller</label>
                    <SelectHours
                        onChange={e => dispatch(selectCompanionFwdTrip({
                            fwdTrip: e.currentTarget.value,
                            index
                        }))}
                        hourSelected={companion.fwdTrip}
                        zeroOption
                    />
                </FormGroupLeft>
                <FormGroupRight>
                    <label htmlFor="trajetRetour">Temps de trajet retour</label>
                    <SelectHours
                        onChange={e => dispatch(selectCompanionRtnTrip({
                            rtnTrip: e.currentTarget.value,
                            index
                        }))}
                        hourSelected={companion.rtnTrip}
                        zeroOption
                    />
                </FormGroupRight>
            </FormRow>
            :
            <FormRow>
                <BaseFormGroupDiv>
                    <label htmlFor="nbHour">Zone</label>
                    <BaseSelect
                        onChange={e => dispatch(selectCompanionZone({
                            zone: e.currentTarget.value,
                            index
                        }))}
                        defaultValue={companion.zone}
                    >
                        {
                            zones.map((zone, index) =>
                                <option key={index} value={zone}>
                                    Zone {zone}
                                </option>
                            )
                        }
                    </BaseSelect>
                </BaseFormGroupDiv>
            </FormRow>
        }
        { companion.commentChoice !== 'oth' ?
            <FormRow>
                <BaseFormGroupDiv>
                    <label>Commentaires</label>
                    <BaseSelect
                        onChange={e => dispatch(chooseCompanionComment({
                            commentChoice: e.currentTarget.value,
                            index
                        }))}
                        defaultValue={companion.commentChoice}
                    >
                        {
                            commentChoices.map((choice, index) =>
                                <option key={index} value={choice.value}>{choice.text}</option>
                            )
                        }
                    </BaseSelect>
                </BaseFormGroupDiv>
            </FormRow>
            :
            <FormRow>
                <FormGroupLeft>
                    <label>Commentaires</label>
                    <BaseSelect
                        onChange={e => dispatch(chooseCompanionComment({
                            commentChoice: e.currentTarget.value,
                            index
                        }))}
                        defaultValue={companion.commentChoice}
                    >
                        {
                            commentChoices.map((choice, index) =>
                                <option key={index} value={choice.value}>{choice.text}</option>
                            )
                        }
                    </BaseSelect>
                </FormGroupLeft>
                <FormGroupRight>
                    <label>Précisions</label>
                    <BaseInput
                        type="text"
                        id="num"
                        onChange={e => dispatch(typeCompanionComment({
                            comment: e.currentTarget.value,
                            index
                        }))}
                        value={companion.comment ? companion.comment : ''}
                        error={errors.includes("companion|" + index.toString() + "|comment")}
                    />
                </FormGroupRight>
            </FormRow>
        }
    </CompanionDiv>
;

const hoursSelect = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12];
export const SelectHours = ({
    onChange,
    hourSelected,
    zeroOption = false
}) =>
    <BaseSelect onChange={onChange} value={hourSelected}>
        { zeroOption ?
            <option value={0}>0</option>
            :
            <option hidden/>
        }
        {
            hoursSelect.map((hour, index) =>
                <option
                    value={parseFloat(hour)}
                    key={index}
                >
                    {hour}
                </option>
            )
        }
    </BaseSelect>
;

export const SelectHoursArrow = ({
    hours,
    onClickLeft,
    onClickRight,
}) =>
    <FormRow style={{textAlign: 'center'}}>
        <BaseFormGroupDiv>
            <FontAwesomeIconButton
                icon={'arrow-circle-left'}
                size={'lg'}
                onClick={onClickLeft}
            />
        </BaseFormGroupDiv>
        <BaseFormGroupDiv>
            {hours}
        </BaseFormGroupDiv>
        <BaseFormGroupDiv>
            <FontAwesomeIconButton
                icon={'arrow-circle-right'}
                size={'lg'}
                onClick={onClickRight}
            />
        </BaseFormGroupDiv>
    </FormRow>
;

export const MailTo = ({
    mail = 'htirel@air8.fr;contact@air8.fr;abeaudu@air8.fr',
    subject
}) =>
    <a href={'mailto:' + mail + '?subject=' + subject}>
        Envoyer un mail
    </a>
;

export const LoadingContainer = styled.div`
    text-align: center;
`;

export const MainDiv = styled(BaseDiv)`
    @media (min-width: 768px) {
        width: 90%;
    }
    @media (min-width: 992px) {
        width: 70%;
    }
    @media (min-width: 1200px) {
        width: 50%;
    }
`;

export const BaseSelect = styled.select`
    display: block;
    width: 100%;
    margin: 6px 0px;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    border: ${props => props.error ? 
    "1px solid #D47576" : "1px solid #ccc"};
    box-shadow: ${props => props.error ? 
    "inset 0 1px 5px #D47576" : "inset 0 1px 1px rgba(0,0,0,.075)"};
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
`;

export const FormRow = styled.div`
    display: flex;
    transition: margin-right 2s .5s;
`;

export const FormGroupRight = styled(BaseFormGroupDiv)`
    padding-left: 3px;
`;

export const FormGroupLeft = styled(BaseFormGroupDiv)`
    padding-right: 3px;
`;

export const SmallButton = styled(BaseButton)`
    width: 100%;
    height: 30px;
    font-size: none;
    padding: 3px;
    border-radius: 20px;
    margin-top: 25px;
`;

export const DeleteButtonContainer = styled.div`
    text-align: right;
    padding-right: 15px;
`;

export const FontAwesomeIconButton = styled(FontAwesomeIcon)`
    transition: all .1s ease-in-out;
    &:hover {
        opacity: .5;
        transform: scale(1.1);
    }
    cursor: pointer;
`;

export const ArrowToList = styled(FontAwesomeIconButton)`
    position: absolute;
`;

export const DeleteButton = styled(FontAwesomeIconButton)`
    width: 100%;
    max-width: 30px;
    height: 30px;
    font-size: none;
    padding: 3px;
    position: absolute;
`;

export const AddButton = styled(BaseButton)`
    background: linear-gradient(to left, #494a4c, #B2B7B9);
`;

export const CompanionDiv = styled(BaseDiv)`
    padding: 0px 10px 5px 10px;
    width: 100%;
    @media (min-width: 768px) {
        width: 100%;
    }
    @media (min-width: 992px) {
        width: 100%;
    }
    @media (min-width: 1200px) {
        width: 100%;
    }
    margin: 6px 0px;
    box-shadow: 0px 1px 3px black;
    border-radius: 3px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
`;

export const UsernameContainer = styled.div`
    position: relative;
    text-align: center;
`;

export const UsernameBadge = styled.span`
    position: absolute;
    right: 0;
    top: 0;
    margin-right: -10px;
    margin-top: -20px;
    font-size: 12px;
    padding: 8px;
    border-radius: 20px;
    background: white;
    color: white;
    background: linear-gradient(to left, #004273, #019ee3);
`;

export const LinkWithoutStyle = styled(Link)`
    color: inherit;
`;
