import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    loading: true,
    year: null,
    week: null,
    valid: false,
    captures: []
};

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        setCaptures: (state, action) => ({
            ...state,
            captures: action.payload
        }),
        setValid: (state, action) => ({
            ...state,
            valid: action.payload
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload
        }),
    }
});

export const fetchList = (year, week) => dispatch => {
    dispatch(setLoading(true));
    fetch(process.env.REACT_APP_API_URL + '/api/list/' + year + '/' + week, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            dispatch(setLoading(false));
            if (window.location.href.indexOf('/' + year + '/' + week) !== -1) {
                if (data.status === "success") {
                    dispatch(setCaptures(data.response.list));
                    dispatch(setValid(data.response.valid));
                } else {
                    // TODO : error message
                }
            }
        });
};

export const {actions, reducer} = listSlice;

export const {
    setCaptures,
    setValid,
    setLoading
} = actions;